import { baseUrl } from './Config';

const resStatus = (res) => {
  if (!res.ok) {
    return res.json().then((errorData) => {
      return Promise.reject({ status: res.status, message: errorData.message });
    });
  }

  return res.json();
};

export const register = (login, email, password) => {
  return fetch(`${baseUrl}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ login, email, password }),
  }).then(resStatus);
};

export const authorize = (login, password) => {
  return fetch(`${baseUrl}/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({ login, password }),
  }).then(resStatus);
};

export const getContent = (token) => {
  return fetch(`${baseUrl}/profile`, {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
  }).then(resStatus);
};