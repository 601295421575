import { useCallback, useState } from "react";

export default function useFormWithValidation() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const validateLogin = (login) => {
    const regex = /^[a-zA-Z][a-zA-Z0-9._-]{2,19}$/;
    return regex.test(login);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password, duplicatePassword = false) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,64}$/;

    return duplicatePassword ? regex.test(password) && password === values.password : regex.test(password);
  };

  function checkValid(input) {
    switch (input.name) {
      case 'login':
        return validateLogin(input.value);
      case 'email':
        return validateEmail(input.value);
      case 'password':
        return validatePassword(input.value);
      case 'password_1':
        return validatePassword(input.value, true);
      default:
        return true;
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: target.validationMessage });
    setIsValid(target.closest('form').checkValidity() && checkValid(target));
  };

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid]
  );

  return { values, handleChange, errors, isValid, resetForm, setValues };
}