import React from 'react';
import { useNavigate } from "react-router-dom";
import useFormWithValidation from '../../utils/Validator';

function Recovery(props) {
  const { values, handleChange, isValid } = useFormWithValidation('form__input_theme_invalid');
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();


  }

  return (
    <form className='form' onSubmit={handleSubmit} noValidate>
      <input className="form__input" type="text" name='login' id='login'
        placeholder="Введите имя пользователя (логин)" minLength="3" maxLength="20" required
        value={values.login ? values.login : ''} onChange={handleChange} />

      <input className="form__input" type="email" name='email' id='email'
        placeholder="Введите email" minLength="6" maxLength="254" required
        value={values.email ? values.email : ''} onChange={handleChange} />

      <button className="form__confirm-button" type="submit" disabled={!isValid}>Восстановить пароль</button>
      <div className="form__buttons">
        <button className="form__button" onClick={() => navigate('/login')} type='button'>Вход</button>
        <button className="form__button" onClick={() => navigate('/signup')} type='button'>Регистрация</button>
      </div>
    </form>
  );
};

export default Recovery;