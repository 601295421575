import React, { useState, useEffect } from 'react';

function Table(props) {
  const createSortPanel = (headers) => {
    const initialSortPanel = {};
    headers.forEach((_, index) => {
      initialSortPanel[`searchWrapp${index}`] = false;
    });
    return initialSortPanel;
  };

  const [sortPanel, setSortPanel] = useState(() => createSortPanel(props.headers));
  const [sorts, setSort] = useState({});
  const [sortedData, setSortedData] = useState(props.data);
  const [sortOrder, setSortOrder] = useState({ column: null, direction: 'asc' });
  const [columnWidths, setColumnWidths] = useState([]);

  // Инициализация ширины столбцов, равномерно распределяем ширину колонок
  useEffect(() => {
    const tableWidth = document.querySelector('.table').clientWidth; // ширина родителя
    const numColumns = props.headers.length;
    const columnWidth = tableWidth / numColumns; // равномерное распределение ширины
    const widths = props.headers.map(() => columnWidth); // Ширина каждого столбца одинаковая
    setColumnWidths(widths);

    setSortedData(props.data);
  }, [props.headers, props.data]);

  // Функция для переключения фильтра
  function handleSortPanel(event) {
    const target = event.target;
    const name = target.name;

    setSortPanel((prevSortPanel) => {
      const updatedSortPanel = {};

      Object.keys(prevSortPanel).forEach((key) => {
        updatedSortPanel[key] = key === name ? !sortPanel[name] : false;
      });

      return updatedSortPanel;
    });
  }

  // Функция для изменения ширины столбца
  const handleMouseDown = (index, e) => {
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const handleMouseMove = (e) => {
      const newWidth = Math.max(startWidth + (e.clientX - startX), 100); // Минимальная ширина столбца 100px
      setColumnWidths((prevWidths) =>
        prevWidths.map((width, i) => (i === index ? newWidth : width))
      );
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Функция для обработки изменения фильтра
  const handleChangeSort = (e) => {
    const { name, value } = e.target;
    setSort((prevSorts) => ({
      ...prevSorts,
      [name]: value
    }));
  };

  // Функция для сортировки по столбцам
  const handleSort = (index) => {
    const column = props.headers[index].key;
    const direction = sortOrder.column === column && sortOrder.direction === 'asc' ? 'desc' : 'asc';

    const sorted = [...sortedData].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortOrder({ column, direction });
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>
      <table className='table' id={props.id}>
        <thead>
          <tr className='table__row' style={{ gridTemplateColumns: columnWidths.map(width => `${width}px`).join(' ') }}>
            {props.headers.map((item, index) => {
              return <th key={index} style={{ minWidth: `${columnWidths[index]}px` }}>
                <p className='table__header-item' onClick={() => handleSort(index)}>{item.header}</p>
                <span></span>
                <span onMouseDown={(e) => handleMouseDown(index, e)}></span>
              </th>
            })}
          </tr>
        </thead>
        <tbody>
          {sortedData
            .map((row, rowIndex) => (
              <tr key={rowIndex} style={{ gridTemplateColumns: columnWidths.map(width => `${width}px`).join(' ') }}>
                {props.template(row, rowIndex)}
              </tr>
            ))}
        </tbody>
        <tfoot>

        </tfoot>
      </table>
    </div>
  );
}

export default Table;