import React, { useState, useEffect } from 'react';
import Table from '../utils/Table';

function Admin(props) {
  const [data, setData] = useState([]);

  const headers = [
    {
      header: 'Ид'
    },
    {
      header: 'Название'
    },
    {
      header: 'email'
    },
    {
      header: 'Телефон'
    },
    {
      header: 'Расписание'
    },
    {
      header: 'Координаты'
    },
    {
      header: 'Адрес'
    },
    {
      header: 'Данные организации'
    },
  ]

  function getData(api) {
    api.getAllCompany().then(res => {
      setData(res);
    })
      .catch(err => {
        console.log(err);
      })
  }

  function fileButtonHandler(event) {
    props.setContPopup(true);

  }

  function refreshHandler() {
    window.location.reload();
    getData(props.Api);
  }

  function template(row, rowIndex) {
    return <>
      <td>{row.id}</td>
      <td>{row.name}</td>
      <td>{row.email}</td>
      <td>{row.phone}</td>
      <td>{row.schedule}</td>
      <td>{row.coords}</td>
      <td>{row.address}</td>
      <td><button id={rowIndex} className='table__container-button table__container-button_theme_small'
        onClick={fileButtonHandler}><i className='table__icon-info'></i></button></td>
    </>
  };

  useEffect(() => {
    if (props.Api)
      getData(props.Api);
  }, [props.Api]);

  return (
    <div className='content'>
      <div className='certificate'>
        <fieldset className='certificate__buttons'>
          <button className='certificate__button' name='newRequest' onClick={() => { props.popupHandler(!props.isOpen) }}>Создать организацию</button>
          <button className='certificate__button' onClick={refreshHandler}>Обновить таблицу</button>
        </fieldset>

        <Table headers={headers} data={data} template={template} setContPopup={props.setContPopup} setContainerPopupData={props.setContainerPopupData} />
      </div>
    </div>
  );
};

export default Admin;