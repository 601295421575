import { createContext } from "react";

export const CurrentUser = {
  id: null,
  login: null,
  email: null,
  verified_email: null,
  profile: {
    surname: '',
    name: '',
    patronymic: '',
    imIP: null,

    phone: '',
    email_contact: '',

    accept: false
  }
};

export const CurrentUserContext = createContext(CurrentUser);