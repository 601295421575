import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function Navigation(props) {
  const location = useLocation();
  const pathName = new Map([
    ["/profile", "Профиль"],
    ["/company", "Организация"],
    ["/request", "Заявки"],
    ["/admin", "Администрирование"],
  ]);

  return (
    <ul className="navigation">
      <li className="navigation__item"><Link to="/profile">Главная</Link></li>
      <li className="navigation__item"><span>{pathName.get(location.pathname)}</span></li>
    </ul>
  );
};

export default Navigation;
