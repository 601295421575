import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import useFormWithValidation from '../utils/Validator';

function Company(props) {
  const user = useContext(CurrentUserContext).profile;
  const { values, handleChange, isValid, errors, resetForm, setValues } = useFormWithValidation();

  function handleSubmit(e) {
    e.preventDefault();

    props.Api.updateProfile(values)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.log(err)
      });
  };

  useEffect(() => {
    if (props.Api)
      props.Api.getCompany().then(res => {
        setValues(res);
      }).catch(() => {
        setValues({
          name: '',
          email: '',
          phone: '',
          schedule: '',
          coords: ''
        });
      })
    else
      setValues({
        name: '',
        email: '',
        phone: '',
        schedule: '',
        coords: ''
      });

  }, [props.Api, user]);

  function inputTemplate(id, type, disabled = false, name, placeholder, isRequired = false, validMessage = 'Поле имеет ошибочный формат', minLength = 2, maxLength = 50, pattern = '.*', onChange = handleChange) {
    return (
      <div className='profile__input-box'>
        <label className='profile__label' htmlFor={id}>{name}</label>
        <input className={`profile__input ${errors[id] ? 'profile__input_theme_invalid' : ''}  ${errors[id] === '' ? 'profile__input_theme_valid' : ''}`}
          type={type}
          id={id} name={id}
          placeholder={placeholder}
          minLength={minLength} maxLength={maxLength}
          value={values[id] || ''}
          onChange={onChange} required={isRequired}
          disabled={!disabled}
          pattern={pattern} />
        <label className={`profile__label_valid`} style={{ 'display': errors[id] ? 'block' : 'none' }} htmlFor={id}>{validMessage}</label>
      </div>
    )
  }

  return (
    <div className='profile' onSubmit={handleSubmit} noValidate>
      <h2 className='profile__tittle'>Заполнить информацию организации</h2>

      <form className='profile__content' noValidate>
        <fieldset className='profile__item'>
          {inputTemplate('orgName', 'text', true, 'Наименование организации', 'Введите наименование', true)}
          {inputTemplate('orgAddres', 'text', true, 'Юридический адрес', 'Введите адрес', true)}
          {inputTemplate('orgSchedule', 'text', true, 'График работы', '9:00-18:00 Пн-Пт', true)}
        </fieldset>

        <fieldset className='profile__item'>
          {inputTemplate('orgPhone', 'text', true, 'Контактный номер', '+7(812)123-45-67', true, 'Формат +7(812)123-45-67 или 88121234567', 2, 16)}
          {inputTemplate('orgEmail_contact', 'email', true, 'Контактный email', 'email@email.ru', true, 'Формат email@email.ru', 2, 256)}
          {inputTemplate('orgCoords', 'text', true, 'Координаты яндекс', '59.943988, 30.306329', true, 'Формат 59.943988, 30.306329', 2, 256)}
        </fieldset>
        <button id='submit' className='profile__submit' type='submit' disabled={!isValid}>Сохранить</button>
      </form>
    </div>
  );
};

export default Company;
