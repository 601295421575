import { baseUrl } from './Config';

const handleResponse = (res) => {
  if (!res.ok) {
    return res.json().then((errorData) => {
      return Promise.reject({ status: res.status, message: errorData.message });
    });
  }

  return res.json();
};

class Api {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  };

  updateProfile = async (user) => {
    const url = `${this._baseUrl}/profile`;

    return fetch(url, {
      headers: this._headers,
      method: "PATCH",
      body: JSON.stringify(user),
    }).then(handleResponse);
  };

  createCompany = async (company) => {
    const url = `${this._baseUrl}/create-company`;

    return fetch(url, {
      headers: this._headers,
      method: "POST",
      body: JSON.stringify(company),
    }).then(handleResponse);
  };

  getRequests = async () => {
    const url = `${this._baseUrl}/get-requests`;

    return fetch(url, {
      headers: this._headers,
    }).then(handleResponse);
  };

  updateRequestStatus = async (status, id) => {
    const url = `${this._baseUrl}/set-request-status`;

    return fetch(url, {
      headers: this._headers,
      method: "PATCH",
      body: JSON.stringify({ status: status, id: id }),
    }).then(handleResponse);
  };

  getCompany = async () => {
    const url = `${this._baseUrl}/get-company`;

    return fetch(url, {
      headers: this._headers,
    }).then(handleResponse);
  };

  getAllCompany = async () => {
    const url = `${this._baseUrl}/get-all-company`;

    return fetch(url, {
      headers: this._headers,
    }).then(handleResponse);
  };

};

const createApi = (token) => new Api({
  baseUrl: baseUrl,
  headers: {
    authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export default createApi;
