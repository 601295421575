import React, { useState } from 'react';
import useFormWithValidation from '../utils/Validator';

function ContainerPopup({ component: Component, ...props }) {
  const [navSelect, setNavSelect] = useState(1);
  const { values, handleChange, isValid, resetForm } = useFormWithValidation('profile__input_theme_invalid', 'profile__input_theme_valid');

  function handleSubmit(e) {
    e.preventDefault();

    console.log(values);
    window.location.reload();
    handleCloseButton();
  }

  function handleCloseButton() {
    props.popupHandler(!props.isOpen);
    props.setContainerPopupData({});
  }

  const handleNavClick = (event) => {
    const items = document.querySelectorAll('.popup__nav-item');
    items.forEach(item => {
      item.classList.remove('popup__nav-item_theme_select');
    });

    event.target.classList.add('popup__nav-item_theme_select');
    setNavSelect(event.target.id);
  };

  function defaultInput(text, id, placeholder, isRequired = false, validMessage = '', minLength = 2, maxLength = 20, type = 'text',) {
    return (
      <div className='profile__input-box'>
        <label className='profile__label' htmlFor={id}>{text}</label>
        <input className='profile__input' type={type} id={id} name={id} placeholder={placeholder} minLength={minLength} maxLength={maxLength}
          value={values[id] || ''} onChange={handleChange} required={isRequired} />
        <label className='profile__label_valid' htmlFor={id}>{validMessage}</label>
      </div>
    )
  }

  return (
    <div className={`popup ${props.isOpen ? 'popup_them_open' : ''}`}>
      <div className='popup__container'>
        <div className='popup__tittle_wrap'>
          <h5 className='popup__tittle'>Информация по организации</h5>
          <button className='popup__close_button' onClick={handleCloseButton}></button>
        </div>

        <div className='popup__form' noValidate>
          <div className='popup__wrapp'>
            <ul className='popup__nav'>
              <li className='popup__nav-item popup__nav-item_theme_select' id={1} onClick={handleNavClick}>Данные организации</li>
            </ul>
            <div className='popup__data'>
              <form className='profile__content' onSubmit={handleSubmit} noValidate>
                <fieldset className='profile__item'>
                  {defaultInput('Организация', 'name', 'Название организации', true, 'Поле Организация имеет ошибочный формат')}
                  {defaultInput('Адрес', 'address', 'Введите адрес', true, 'Поле Адрес имеет ошибочный формат')}
                  {defaultInput('График работы', 'schedule', '9:00-18:00 Пн-Пт', undefined, 'Поле график имеет ошибочный формат')}
                </fieldset>

                <fieldset className='profile__item'>
                  {defaultInput('Контактный номер', 'phone', '+7(812)123-45-67', true)}
                  {defaultInput('Контактный email', 'email', 'email@email.ru', true, undefined, undefined, undefined, 'email')}
                  {defaultInput('Координаты яндекс', 'coords', '59.943988, 30.306329', true)}
                </fieldset>
                <button id='submit' className='profile__submit' type='submit' disabled={!isValid}>Сохранить</button>
              </form>
            </div>
          </div>

          <div className='popup__submit_wrap'>
            <button type="submit" className='popup__submit'>Ок</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ContainerPopup;