import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function Sidebar(props) {
  const location = useLocation();

  const links = [
    ['/profile', 'Профиль', 'nav__user'],
    ['/company', 'Организация', 'nav__company'],
    ['/request', 'Заявки', 'nav__badge'],
    ['/admin', 'Администрирование', 'nav__badge']
  ];

  return (
    <div className={`${props.isOpen ? 'sidebar sidebar_open' : 'sidebar'} ${props.isMini ? 'sidebar_mini' : ''}`}>
      <nav className='nav'>
        {
          links.map((link, index) => (
            <Link key={index} to={link[0]} className={location.pathname === link[0] ? 'nav__link nav__link_active' : 'nav__link'}><i className={`${location.pathname === link[0] ? `nav__icon ${link[2]} nav__icon_active` : `nav__icon ${link[2]}`}`
              + ` ${props.isMini ? 'nav__icon_min' : ''}`}> </i>{props.isMini ? '' : link[1]}</Link>
          ))
        }
      </nav>
      <button type="button" className={'sidebar__minimizer' + `${props.isMini ? ' sidebar__minimizer_rotate' : ''}`} onClick={props.handleMiniClick} />
    </div>
  );
};

export default Sidebar;
