import React, { useState, useEffect } from 'react';
import ConfirmModal from './ConfirmModal';

function Request(props) {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [confirmModalData, setConfirmModalModalData] = useState({ id: 0, name: '', status: 0 });
  const [values, setValues] = useState({ all: true, new: false, delivery: false, shin: false, cancel: false });
  const [filter, setFilter] = useState(false);

  function getData(api) {
    api.getRequests()
      .then(res => {
        setData(res.sort((a, b) => { return a.status - b.status; }));
        setFilterData(res.sort((a, b) => { return a.status - b.status; }));
      })
      .catch(err => {
        console.log(err);
      })
  }

  function refreshHandler() {
    getData(props.Api);
  }

  function formatStatus(status) {
    if (status == 1) {
      return <span className='card__item card__status' style={{ backgroundColor: '#0000FF' }}>Новая</span>
    } else if (status == 2) {
      return <span className='card__item card__status' style={{ backgroundColor: '#CD853F' }}>Доставка подтверждена</span>
    } else if (status == 3) {
      return <span className='card__item card__status' style={{ backgroundColor: '#50C878' }}>Шиномонтаж подтвержден</span>
    } else if (status == 4) {
      return <span className='card__item card__status' style={{ backgroundColor: '#d9534f' }}>Отменено</span>
    }
  }

  function handleStatusButtons(id, status) {
    setConfirmModalIsOpen(true);
    let name = '';
    if (status == 1)
      name = 'Подтвердить доставку';
    else if (status == 2)
      name = 'Подтвердить шиномонтаж';
    else if (status == 3)
      name = 'Отменить заявку';
    else
      name = 'Отменить заявку';
    setConfirmModalModalData({ id: id, status: status + 1, name: name });
  }

  function filterHandler() {
    setFilter(!filter);
  }

  const filterDataHandler = (e) => {
    e.preventDefault();

    if (values.all) return setData(data);

    setFilterData(data.filter(item => {
      return (
        (values.new && item.status === 1) ||
        (values.delivery && item.status === 2) ||
        (values.shin && item.status === 3) ||
        (values.cancel && item.status === 4)
      );
    }));
  };

  useEffect(() => {
    if (props.Api)
      getData(props.Api);
  }, [props.Api]);

  return (
    <div className='content'>
      <div className='request'>
        <fieldset className='request__buttons'>
          <button className='request__button' onClick={refreshHandler}>Обновить список</button>
          <div className='request__filter' onClick={filterHandler}>
            <form className='request__wrapp-filter' style={{ display: filter ? 'flex' : 'none' }} noValidate onSubmit={filterDataHandler}>
              <p>Фильтр</p>
              <div className='request__wrapp-item'>
                <input type='checkbox' id='all' name='all' checked={values.all === true} onChange={() => setValues({ all: !values.all })} />
                <label className='profile__label ' htmlFor='all'>Все</label>
              </div>
              <div className='request__wrapp-item'>
                <input type='checkbox' id='new' name='new' checked={values.new === true} onChange={() => setValues({ ...values, new: !values.new, all: false })} />
                <label className='profile__label ' htmlFor='new'>Новая</label>
              </div>
              <div className='request__wrapp-item'>
                <input type='checkbox' id='delivery' name='delivery' checked={values.delivery === true} onChange={() => setValues({ ...values, delivery: !values.delivery, all: false })} />
                <label className='profile__label ' htmlFor='delivery'>Доставка подтверждена</label>
              </div>
              <div className='request__wrapp-item'>
                <input type='checkbox' id='shin' name='shin' checked={values.shin === true} onChange={() => setValues({ ...values, shin: !values.shin, all: false })} />
                <label className='profile__label ' htmlFor='shin'>Шиномонтаж подтвержден</label>
              </div>
              <div className='request__wrapp-item'>
                <input type='checkbox' id='cancel' name='cancel' checked={values.cancel === true} onChange={() => setValues({ ...values, cancel: !values.cancel, all: false })} />
                <label className='profile__label' htmlFor='cancel'>Отменено</label>
              </div>

              <button className='request__filter-button' type='submit'>Применить</button>
            </form>
          </div>
        </fieldset>

        <ul className='request__list'>
          {filterData.map((item, index) => {
            return <li className='card' key={index} name={item.id}>
              <div className='card__header'>
                <span className='card__item card__number'>№{item.id}</span>
                {formatStatus(item.status)}
                <span className='card__item card__date'>{item.date}</span>
              </div>

              <div className='card__content'>
                <p><strong>Дата доставки:&nbsp;</strong>{item.reqDate}</p>
                <p><strong>Продавец:&nbsp;</strong>{item.reqSeller}</p>
                <p><strong>Шины:&nbsp;</strong>{item.reqDesc}</p>
                <p><strong>Имя:&nbsp;</strong>{item.reqName}</p>
                <p><strong>Телефон:&nbsp;</strong> <a href={`tel:${item.reqPhone}`}>{item.reqPhone}</a></p>
              </div>

              <div className='card__actions'>
                <button disabled={item.status > 1} onClick={() => handleStatusButtons(item.id, item.status)}>Подтвердить доставку</button>
                <button disabled={item.status <= 1} onClick={() => { document.location.href = `tel:${item.reqPhone}` }}>Позвонить клиенту</button>
                <button disabled={!(item.status == 2)} onClick={() => handleStatusButtons(item.id, item.status)}>Подтвердить шиномонтаж</button>
                <button className='card__actions_cancel' disabled={item.status >= 3} onClick={() => handleStatusButtons(item.id, 3)}>Отменить заявку</button>
              </div>
            </li>
          })}
        </ul>
      </div>

      <ConfirmModal Api={props.Api} isOpen={confirmModalIsOpen} popupHandler={setConfirmModalIsOpen} data={data} setData={setData}
        confirmModalData={confirmModalData} setConfirmModalModalData={setConfirmModalModalData} />
    </div >
  );
};

export default Request;