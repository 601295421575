import React from 'react';

function ConfirmModal(props) {

  function handleSubmit(e) {
    e.preventDefault();

    props.Api.updateRequestStatus(props.confirmModalData.status, props.confirmModalData.id)
      .then(res => {
        if (res) {
          window.location.reload();
        }
      })

    handleCloseButton();
  }

  function handleCloseButton() {
    props.popupHandler(!props.isOpen);
    props.setConfirmModalModalData({ id: 0, status: 0, name: '' });
  }

  return (
    <div className={`popup ${props.isOpen ? 'popup_them_open' : ''}`}>
      <div className='popup__container popup__container_theme_small'>
        <div className='popup__tittle_wrap'>
          <h5 className='popup__tittle'>{props.confirmModalData.name}</h5>
        </div>

        <form className='popup__submits' onSubmit={handleSubmit} noValidate>
          <button type='submit' className='popup__submit' >Да</button>
          <button type='button' className='popup__submit' style={{ backgroundColor: '#d9534f' }} onClick={handleCloseButton}>Отмена</button>
        </form>
      </div>
    </div>
  )
}

export default ConfirmModal;